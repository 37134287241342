<template>
    <ul class="sv-tree-wrap">
        <li v-for="item in list" class="node-wrap" :key="item[nodeKey]">
            <!-- 树节点 -->
            <span :id="item.code" class="text-wrap">
                <!-- 节点图标 -->
                <icon :class="getIconCls(item)" @click="handleExpand(item)" />

                <!-- 节点文本容器 -->
                <span
                    :class="getNodeCls(item)"
                    @click="handleCurrentSelectNode(item)"
                    @contextmenu="handleNodeContextMenu(item, $event)"
                >
                    <!-- 节点文本 -->
                    <span :class="getNodeTextCls(item)" :title="item.tip" v-html="item.title"> </span>

                    <!-- 更多icon -->
                    <Icon
                        v-if="isNodeMoreIcon"
                        id="tree-node-more-icon"
                        class="more-icon"
                        type="md-more"
                        @click.stop="handleTreeClickMore(item, $event)"
                    />
                </span>
            </span>

            <!-- 子节点 -->
            <tree
                v-if="isShowChildNode(item)"
                :isNodeExpand="isNodeExpand"
                :isNodeMoreIcon="isNodeMoreIcon"
                :nodeKey="nodeKey"
                :list="item.children"
                @onSelectionNode="handleTreeSelectNode"
                @onClickMore="handleTreeClickMore"
                @onContextMenu="handleNodeContextMenu"
            />
        </li>
    </ul>
</template>

<script>
export default {
    name: 'tree',

    data() {
        return {};
    },

    props: {
        nodeKey: {
            type: String
        },
        list: {
            type: Array,
            default: () => []
        },
        isNodeExpand: {
            type: Boolean,
            default: true
        },
        isNodeMoreIcon: {
            type: Boolean
        },
        leafNodeIcon: {
            type: String,
            default: 'node-icon ivu-icon-ios-paper-outline'
        },
        expandNodeIcon: {
            type: String,
            default: 'node-icon ivu-icon-md-arrow-dropdown'
        },
        collapseNodeIcon: {
            type: String,
            default: 'node-icon ivu-icon-md-arrow-dropright'
        }
    },

    methods: {
        // 获取节点文本容器
        getNodeCls(item) {
            return {
                'node-text-wrap': true,
                selected: item.selected
            };
        },

        // 获取节点文本样式
        getNodeTextCls(item) {
            return {
                'node-text': true,
                leaf: this.isLeafNode(item)
            };
        },

        // 获取图标样式
        getIconCls(item) {
            if (item.iconCls) {
                return item.iconCls;
            }
            if (this.isLeafNode(item)) {
                return this.leafNodeIcon;
            }
            if (item.expand) {
                return this.expandNodeIcon;
            } else {
                return this.collapseNodeIcon;
            }
        },

        // 处理展开收起
        handleExpand(item) {
            this.$set(item, 'expand', !item.expand);
        },

        // 处理节点文本点击
        handleCurrentSelectNode(item) {
            this.$emit('onSelectionNode', item);

            // 是否节点控制展开收起
            if (this.isNodeExpand) {
                this.handleExpand(item);
            }
        },

        // 处理树节点点击
        handleTreeSelectNode(item) {
            this.$emit('onSelectionNode', item);
        },

        // 处理点击树节点更多
        handleTreeClickMore(item, e) {
            this.$emit('onClickMore', item, e);
        },

        // 节点右击事件
        handleNodeContextMenu(item, e) {
            this.$emit('onContextMenu', item, e);

            e.preventDefault();
        },

        // 是否是叶子节点
        isLeafNode(item) {
            const children = item.children;

            if (!children) {
                return true;
            }

            if (children && children.length === 0) {
                return true;
            }
        },

        // 是否显示子节点
        isShowChildNode(item) {
            const { expand, children } = item;

            return expand && children && children.length;
        }
    }
};
</script>

<style lang="less" scoped>
.sv-tree-wrap {
    padding: 0 0 0 10px;

    .node-wrap {
        margin-top: 2px;
        margin-left: 5px;
        margin-bottom: 2px;

        .text-wrap {
            display: flex;
            align-items: center;
            cursor: pointer;
            .node-icon {
                display: block;
                font-size: 16pt;
            }
            .node-text-wrap {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 5px 2px;
                border-radius: @border-radius-size;
                &:hover {
                    background: @hover-bg-color;
                }
                &.selected {
                    background: @selected-bg-color;
                    color: #0072dd;
                }
                .node-text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    flex: 1;
                    width: 0px;
                }
                .more-icon {
                    width: 20px;
                    font-weight: 700;
                }
            }

            .ivu-icon-ios-paper-outline {
                font-size: 16px;
            }

            .leaf {
                margin-left: 5px;
                padding: 2px 0px;
            }
        }
    }
}
</style>
