export default [
    {
        name: 'M100-1',
        path: '/diagnosereply/detail',
        meta: {
            title: '诊断仪反馈详情'
        },
        component: () => import('@/pages/diagnosereply/detail')
    },
    {
        name: 'M200-1',
        path: '/engineering/engineeringDetail',
        meta: {
            title: '问题详情'
        },
        component: () => import('@/pages/engineering/engineeringDetail')
    },
    {
        name: 'M300-1',
        path: '/boutique/detail',
        meta: {
            title: '精品及改装件'
        },
        component: () => import('@/pages/boutique/detail')
    },
    {
        path: '/no-access',
        meta: {
            title: '无权限',
            notAuth: true
        },
        component: () => import('@/pages/errorPages/401')
    },

];
