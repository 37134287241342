<template>
    <div class="sv-upload-field">
        <div class="sv-upload">
            <Input ref="filebox" :readonly="true" :value="model ? fileName : ''" />
            <a v-if="url" href="javascript:;" class="preview" @click="previewFile">预览</a>
            <sv-upload
                :url="uploadUrl"
                @onSuccess="handlerUploadSuccess"
                :format="format"
                :onFormatError="handlerFormatError"
                :maxSize="maxSize"
            >
                <Button icon="ios-cloud-upload-outline" :loading="loading">浏览</Button>
            </sv-upload>
        </div>
        <div class="upload-format-tip" :class="{ error: formatError }">
            提示：1.文件格式为
            <span v-for="(item, i) in format" :key="i">{{ item }}{{ format.length - 1 == i ? '' : '、' }}</span>
            ，大小&lt;{{ parseInt(maxSize) / 1024 }}MB
        </div>
    </div>
</template>

<script>
import config from 'config';
export default {
    data() {
        return {
            fileName: '',
            formatError: false,
            url: '',
            uploadUrl: config.baseURL + '/api/consult/res/upload'
        };
    },
    props: {
        name: {
            type: String,
            default: 'field'
        },
        loading: {
            type: Boolean,
            default: false
        },
        model: {
            type: [Object, Array]
        },
        format: {
            type: Array,
            default: () => []
        },
        maxSize: {
            type: Number
        }
    },
    watch: {
        model: {
            handler(data) {
                if (data) {
                    if (Array.isArray(data)) {
                        // 目前还没用到多文件逻辑，后续需要再根据需求设计
                    } else {
                        this.fileName = data.name || '';
                        this.url = data.uri || '';
                    }
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        handlerUploadSuccess(response) {
            this.loading = false;
            if (response.success) {
                if (response.result.length) {
                    this.fileName = response.result[0].name;
                    this.url = response.result[0].uri;
                    this.$emit('setUploadResult', response.result[0]);
                }
            } else {
                this.$Message.error(response.header.message);
            }
        },
        handlerFormatError() {
            this.formatError = true;
        },
        previewFile() {
            const url = config.resURL + this.url;
            window.open(url, '_blank');
        }
    }
};
</script>

<style lang="less">
@import '~@/less/sdk/index';

.sv-upload-field {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: right;
    .preview {
        cursor: pointer;
        display: inline-block;
        width: 60px;
        text-align: center;
        text-decoration: underline;
    }
    .sv-upload {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }
    .ivu-upload {
        margin-left: 5px;
        .ivu-btn {
            padding: 4px 15px 6px;
            &.ivu-btn-small {
                height: 33px;
            }
        }
    }
    .error {
        color: #ed4014;
    }
}
</style>
