<template>
    <div class="sv-tree-wrap">
        <!-- 树控件 -->
        <tree
            ref="tree"
            :list="renderData"
            :nodeKey="nodeKey"
            :isNodeMoreIcon="isNodeMoreIcon"
            @onSelectionNode="handleOnSelectNode"
            @onClickMore="handleOnClickMore"
            @onContextMenu="handleNodeContextMenu" />

        <!-- 树节点上下文菜单 -->
        <div
            id="tree-context-menu"
            ref="contextMenu"
            class="contextmenu-wrap"
            :style="{ top: top + 'px', left: left + 'px' }">
            <div v-for="(item, idx) in contextMenuItems" :key="idx">
                <div
                    v-if="isEmpty(item.show) || item.show"
                    class="context-item"
                    @click="handleClickContextMenuItem(item)">
                    <span class="item-text">{{ item.text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tree from './tree.vue';

export default {
    components: {
        tree
    },

    data() {
        return {
            list: [],
            leafNodeCodes: [],
            renderData: [],
            selectedNode: null,
            isShowContextMenu: false,
            top: 0,
            left: 0
        };
    },

    created() {
        this.initData(this.data);
        this.initEvents();
    },

    watch: {
        data(data) {
            this.initData(data);
        },

        isExpandAll(expand) {
            this.expandAll(expand);
        },
    },

    props: {
        // 树数据
        data: {
            type: Array,
            default: () => []
        },

        // 节点唯一键字段
        nodeKey: {
            type: String,
            default: 'code'
        },

        // 节点名称字段
        titleKey: {
            type: String,
            default: 'title'
        },

        // 全部展开
        isExpandAll: {
            type: Boolean,
            default: false
        },

        // 节点是否有更多icon
        isNodeMoreIcon: {
            type: Boolean,
            default: false
        },

        // 上下文菜单项
        contextMenuItems: {
            type: Array,
            default: () => []
        },

        // 打开上下文菜单回调
        beforeOpenContextMenu: {
            type: Function
        }
    },

    methods: {
        // 初始化数据
        initData(data) {
            // 扩展字段
            this.extendNodeKey(data);

            // 克隆渲染数据
            this.renderData = this.cloneData(data);

            // 树结构数据转换list
            this.list = this.getTreeToList(this.renderData);

            // 获取叶子节点
            this.leafNodeCodes = this.getleafNodeCodes(this.list);
        },

        // 初始化事件
        initEvents() {
            document.body.addEventListener('click', e => {
                if (e.target.id !== 'tree-node-more-icon' && this.isShowContextMenu) {
                    this.removeContextMenu();
                }
            });
        },

        // 点击上下文菜单选项
        handleClickContextMenuItem(item) {
            this.$emit('onClickContextMenuItem', item);
            this.removeContextMenu();
        },

        // 处理选择节点
        handleOnSelectNode(item) {
            this.setSelecteNode(item);
        },

        // 处理点击节点的[more icon]
        handleOnClickMore(item, e) {
            this.showNodeContextMenu(item, e);
            this.$emit('onClickMore', item);
        },

        // 处理右击节点事件
        handleNodeContextMenu(item, e) {
            this.setSelecteNode(item);
            this.showNodeContextMenu(item, e);
        },

        // 显示上下文菜单
        showNodeContextMenu(item, e) {
            let isShow = true;

            if (!this.isEmpty(this.beforeOpenContextMenu)) {
                isShow = this.beforeOpenContextMenu(this.contextMenuItems, item);
            }

            if (isShow && this.contextMenuItems.length) {
                this.top = e.pageY;
                this.left = e.pageX;
                this.$refs.contextMenu.style.display = 'block';
                this.isShowContextMenu = true;
                document.body.appendChild(this.$refs.contextMenu);
            }
        },

        // 设置选中节点
        setSelecteNode(item) {
            this.clearSelectNode();
            this.selectedNode = item;
            this.$set(item, 'selected', true);
            this.$emit('onSelectNode', item);
        },

        // 过滤节点
        filter(keyword) {
            this.renderData = this.isEmpty(keyword)
                ? this.cloneData(this.data)
                : this.getFilterData(this.data, keyword);

            this.list = this.getTreeToList(this.renderData);
            this.leafNodeCodes = this.getleafNodeCodes(this.list);
        },

        // 获取过滤数据
        getFilterData(data, keyword) {
            let newData = [];

            data.forEach(node => {
                let subNodes = [];
                const children = node.children;
                const newNode = this.cloneNode(node);

                if (children && children.length > 0) {
                    subNodes = this.getFilterData(children, keyword);
                }
                if (subNodes.length > 0) {
                    newNode.title = this.highlightNode(newNode.title, keyword);
                    newNode.expand = true;
                    newNode.children = subNodes;
                    newData.push(newNode);
                } else if (node.title.indexOf(keyword) > -1) {
                    newNode.title = this.highlightNode(newNode.title, keyword);
                    newNode.children = [];
                    newData.push(newNode);
                }
            });

            return newData;
        },

        // 高亮节点
        highlightNode(title, keyword) {
            if (!this.isEmpty(keyword)) {
                const regexp = new RegExp(keyword, 'g');
                return title.replace(regexp, `<span style="color:#616DF1;">${keyword}</span>`);
            } else {
                return title;
            }
        },

        // 克隆单个节点
        cloneNode(node) {
            let strNode = JSON.stringify(node);

            return JSON.parse(strNode);
        },

        // 克隆树数据
        cloneData(data) {
            let strData = JSON.stringify(data);

            return JSON.parse(strData);
        },

        // 清除选择节点
        clearSelectNode() {
            this.list.map(item => {
                this.$set(item, 'selected', false);
            });
        },

        // 展开全部节点
        expandAll(isExpand) {
            this.list.map(item => {
                this.$set(item, 'expand', isExpand);
            });
        },

        // 选择中树节点根据code
        selectionNode(nodeCode) {
            const selectNode = this.list.filter(item => item[this.nodeKey] == nodeCode);

            if (selectNode.length) {
                this.selectedNode = selectNode[0];
                this.$emit('onSelectNode', selectNode[0]);
                this.expandSelectionNode(this.renderData, nodeCode);

                // 选中节点移到可见区域
                this.$nextTick(() => {
                    this.nodeMoveIntoView(nodeCode);
                });
            }
        },

        // 展开选择的节点
        expandSelectionNode(data, code) {
            let list = [];

            data.forEach(node => {
                let sublist = [];
                const children = node.children;

                this.$set(node, 'expand', false);
                this.$set(node, 'selected', false);

                if (children && children.length > 0) {
                    sublist = this.expandSelectionNode(children, code);
                }
                if (sublist.length > 0) {
                    this.$set(node, 'expand', true);
                    list.push(node);
                    list = list.concat(sublist);
                } else if (node[this.nodeKey] === code) {
                    this.$set(node, 'selected', true);
                    list.push(node);
                }
            });

            return list;
        },

        // 扩展节点的key
        extendNodeKey(data) {
            data.forEach(item => {
                item.tip = item[this.titleKey];

                this.extendNodeKey(item.children || []);
            });
        },

        // 向上移动节点
        upMoveNode() {
            if (!this.isEmpty(this.selectedNode)) {
                const code = this.selectedNode[this.nodeKey];
                const idx = this.leafNodeCodes.indexOf(code);

                if (idx > 0) {
                    this.selectionNode(this.leafNodeCodes[idx - 1]);
                }
            }
        },

        // 向下移动节点
        downMoveNode() {
            if (!this.isEmpty(this.selectedNode)) {
                const code = this.selectedNode[this.nodeKey];
                const idx = this.leafNodeCodes.indexOf(code);

                if (idx < this.leafNodeCodes.length) {
                    this.selectionNode(this.leafNodeCodes[idx + 1]);
                }
            }
        },

        // 将树节点转换为list
        getTreeToList(data) {
            let list = [];

            data.forEach(node => {
                let sublist = [];
                let children = node.children;

                if (children && children.length > 0) {
                    sublist = this.getTreeToList(children);
                }
                if (sublist.length > 0) {
                    list.push(node);
                    list = list.concat(sublist);
                } else {
                    list.push(node);
                }
            });

            return list;
        },

        // 获取全部的叶子节点
        getleafNodeCodes(list) {
            return list.filter(item => this.isLeaf(item)).map(item => item.code);
        },

        // 获取选择节点的路径
        getSelectionNodePath(code) {
            return this.getNodePath(this.data, code);
        },

        // 获取选择节点
        getNodePath(data, code) {
            let nodes = [];

            data.forEach(node => {
                let subNodes = [];
                let children = node.children;

                if (!this.isEmpty(children)) {
                    subNodes = this.getNodePath(children, code);
                }
                if (subNodes.length > 0) {
                    nodes.push(node);
                    nodes = nodes.concat(subNodes);
                } else if (node[this.nodeKey] === code) {
                    nodes.push(node);
                }
            });

            return nodes;
        },

        // 节点移到可见区域
        nodeMoveIntoView(code) {
            const node = this.$refs.tree.$el.querySelectorAll('[id="' + code + '"]');

            if (node && node.length) {
                node[0].scrollIntoView(true);
            }
        },

        // 移除上下文菜单
        removeContextMenu() {
            if (!this.isEmpty(this.$refs.contextMenu)) {
                document.body.removeChild(this.$refs.contextMenu);
            }
            this.isShowContextMenu = false;
        },

        // 判断是否是叶子节点
        isLeaf(item) {
            const children = item.children;

            return this.isEmpty(children);
        },

        // 是否为空
        isEmpty(value) {
            return value == null || value === '' || value === undefined || (Array.isArray(value) && value.length === 0);
        }
    }
};
</script>

<style lang="less" scoped>
.sv-tree-wrap {
    position: relative;
}

.contextmenu-wrap {
    display: none;
    position: absolute;
    background: #fff;
    padding: 5px;
    border-radius: @border-radius-size;
    top: 0px;
    left: 0px;
    border: 1px solid #ddd;
    box-shadow: 5px 5px 3px #ddd;
    z-index: 99;
    width: 120px;

    .context-item {
        padding: 3px 15px;
        cursor: pointer;

        &:hover {
            background: @hover-bg-color;
        }
    }
}
</style>
