import axios from 'axios';
import config from 'config';
import vue from 'vue';
import { storage } from '@/common/storage';

// 创建axios实例
const instance = axios.create({
    baseURL: config.baseURL + '/api',
    timeout: 1000 * 60,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
});

// 定义Request类
class Request {
    constructor() {
        // 实现请求与响应拦截器
        this.requestInterceptors();
        this.responseInterceptors();
    }

    /**
     * GET 请求
     * @param {String} url
     */
    get(url, params) {
        url = params ? url + '?' + params : url;

        return instance.get(url);
    }

    /**
     * POST 提交
     * @param {String} url
     * @param {Object} data
     */
    post(url, data) {
        return instance.post(url, data);
    }

    /**
     * 配置参数请求
     * @param {Object} config
     */
    req(config) {
        return instance.request(config);
    }

    /**
     * 请求拦截器
     */
    requestInterceptors() {
        instance.interceptors.request.use(
            config => {
                return this.handleBeforeRequest(config);
            },
            err => {
                return Promise.reject(err);
            }
        );
    }

    /**
     * 响应拦截器
     */
    responseInterceptors() {
        instance.interceptors.response.use(
            response => {
                return response.data || {};
            },
            err => {
                return this.handleResponseError(err);
            }
        );
    }

    /**
     * 发送请求前处理
     * @param {Object} config
     */
    handleBeforeRequest(config) {
        // 请求头注入Token
        const authorization = storage.getAuthorization();
        Object.assign(config.headers, authorization);

        return config;
    }

    /**
     * 响应错误处理
     * @param {Object} err
     */
    handleResponseError(err) {
        const response = err.response;

        if (!response) {
            vue.prototype.$Notice.error({
                title: '错误',
                desc: err.message
            });
            return;
        }

        switch (response.status) {
            case 0:
                vue.prototype.$Notice.error({
                    title: '错误',
                    desc: '网络访问出错，请与管理联系。'
                });
                break;
            case 401:
                storage.removeStorage();

                vue.prototype.$Notice.error({
                    title: '错误',
                    desc: response.data.msg + ' 即将跳到登录页面！'
                });

                setTimeout(() => {
                    window.location.href = response.data.result.loginUrl;
                }, 3000)
                break;
            case 404:
                vue.prototype.$Notice.error({
                    title: '错误',
                    desc: response.data.status + ' : ' + response.data.error
                });
                break;
            default:
                vue.prototype.$Notice.error({
                    title: '错误',
                    desc: response.data.message
                });
                break;
        }

        return Promise.reject(err);
    }
}

// 初始化Request
const request = new Request();

export { instance, request };
