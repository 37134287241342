// 模板
<template>
    <div>
        <Input
            ref="input"
            icon="md-add"
            :style="{ width: width }"
            :class="{ error: error }"
            @on-click="openSelector"
            @dblclick.native="openSelector"
            @on-clear="handleClear"
            :placeholder="placeholder"
            v-model="fieldValue"
            :disabled="disabled"
            readonly
            clearable
        />

        <!-- 选择器 -->
        <sv-selector-grid
            ref="svSelectorGrid"
            :keyName="keyName"
            :title="title"
            :labelWidth="labelWidth"
            :url="url"
            :multiSelect="multiSelect"
            :rownumber="rownumber"
            @selectionRow="handleSelectionRow"
            @confirmSelection="handleConfirmSelection"
        >
            <search>
                <item
                    v-for="(item, idx) in searchFields"
                    :key="idx"
                    :label="item.label"
                    :type="item.type"
                    :name="item.name"
                    :url="item.url"
                />
            </search>
            <columns>
                <item
                    v-for="item in gridColumns"
                    :key="item.key"
                    :title="item.title"
                    :width="item.width"
                    :align="item.align"
                />
            </columns>
        </sv-selector-grid>
    </div>
</template>


// 脚本
<script>
export default {
    name: 'SvSelectorField',
    props: {
        width: {
            type: String,
            default: '180px'
        },
        model: {
            type: [String, Array, Number]
        },
        title: {
            type: String
        },
        labelWidth: {
            type: Number
        },
        placeholder: {
            type: String,
            default: '双击文本框或点击右边图标选择数据'
        },
        url: {
            type: String
        },
        searchFields: {
            type: Array
        },
        gridColumns: {
            type: Array
        },
        valueField: {
            type: String,
            default: 'code'
        },
        keyName: {
            type: String,
            default: 'code'
        },
        multiSelect: {
            type: Boolean,
            default: () => false
        },
        rownumber: {
            type: Boolean,
            default: () => false
        },
        isError: {
            type: Boolean,
            default: () => false
        },
        disabled: {
            type: Boolean,
            default: () => false
        },
        mappingFields: {
            type: Array,
            default: () => []
        },
        extraParams: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            selectionData: [],
            fieldValue: this.model,
            error: this.isError
        };
    },
    watch: {
        isError() {
            this.error = this.isError;
        },
        model() {
            this.fieldValue = this.model;

            this.selectionData = this.buildSelectionData(this.model);
        }
    },
    methods: {
        handleConfirmSelection(data) {
            let list = data.map(item => {
                return item[this.valueField];
            });
            let value = list.join(',');

            this.setFieldValue(value, data);
        },

        handleSelectionRow(data) {
            const value = data[this.valueField];

            if (!this.multiSelect) {
                this.$refs.svSelectorGrid.closeReset();
            }
            this.setFieldValue(value, data);
        },

        setFieldValue(value, data) {
            const form = this.$parent.FormInstance || this.$parent.form;

            if (form) {
                if (this.mappingFields.length) {
                    this.mappingFields.forEach(item => {
                        form.model[item.to] = this.getFromValue(data, item.from);
                    });
                } else {
                    form.model[this.$parent.prop] = value;
                }
                form.validateField(this.$parent.prop);
            } else {
                this.fieldValue = value;
            }

            this.error = false;
            this.selectionData = data;
            this.$emit('on-change', this.selectionData);
        },

        getFromValue(data, key) {
            if (Array.isArray(data)) {
                return data.map(item => item[key]).join(',');
            } else {
                return data[key];
            }
        },

        handleClear() {
            const form = this.$parent.FormInstance || this.$parent.form;
            const clearValue = this.multiSelect ? null : '';

            this.error = false;
            this.selectionData = clearValue;
            if (form) {
                form.model[this.$parent.prop] = clearValue;
            }
            this.$emit('on-change', this.selectionData);
        },

        openSelector() {
            if (this.disabled) return;

            if (this.multiSelect) {
                this.$refs.svSelectorGrid.show(this.selectionData, this.extraParams);
            } else {
                this.$refs.svSelectorGrid.show([], this.extraParams);
            }
        },

        buildSelectionData(model) {
            let list = [];

            if (typeof model == 'string') {
                list = model.split(',');
            }

            return list.map(val => {
                let item = {};
                item[this.valueField] = val;

                return item;
            });
        }
    }
};
</script>


<style lang="less">
.error {
    .ivu-input {
        border: 1px solid red;
    }
}
</style>