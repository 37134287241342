<template>
    <div class="no-record-wrap">
        <div class="no-record-img">
            <img src="/images/no-record.png" />
        </div>
        <div class="no-record-desc">
            <span>{{ titleZh }}</span>
            <span>{{ titleEn }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titleZh: {
            type: String,
            default: '暂无结果'
        },
        titleEn: {
            type: String,
            default: 'NO RESULTS FOUND'
        }
    }
};
</script>

<style lang="less" scoped>
.no-record-wrap {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-record-desc {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        margin-top: 10px;
    }
}
</style>