// 模板
<template>
    <modal :title="dialogTitle" v-model="isShow" :width="width" @on-cancel="doClose">
        <Form ref="form" :show-message="false" @submit.native.prevent>
            <Row>
                <Col span="24" class="upload-wrap">
                    <span class="file-label"><span class="required-star">*</span> 上传文件:</span>
                    <span :class="{ 'file-box': true, invalid: validateMsg }">
                        <Input
                            :readonly="true"
                            :clearable="true"
                            @on-clear="handleClear"
                            :value="file ? file.name : ''"
                        />
                    </span>
                    <span class="file-button">
                        <sv-upload
                            ref="uploadfile"
                            :url="url"
                            :data="extraParams"
                            @onSuccess="handleSuccess"
                            @onError="handleError"
                            :beforeUpload="beforeUpload"
                            btnUploadSize="default"
                        />
                    </span>
                    <span class="file-template">
                        <Button icon="md-download" @click="downloadTemplate()">下载模板</Button>
                    </span>
                </Col>
                <Col span="24" v-if="validateMsg" class="import-message-wrap">
                    <span class="message">{{ validateMsg }}</span>
                </Col>
                <Col span="24" class="upload-report">
                    <span class="file-label">报告信息:</span>
                    <span class="report-content" v-html="reportMessage" />
                </Col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="text" @click="doClose()">关闭</Button>
            <Button type="primary" @click="doSubmit()" :loading="loading">导入</Button>
        </div>
    </modal>
</template>

// 脚本
<script>
import config from 'config';

export default {
    name: 'SvImport',

    data: function () {
        return {
            dialogTitle: '导入',
            params: {},
            file: null,
            validateMsg: null,
            reportMessage: null,
            loading: false,
            isShow: false
        };
    },

    props: {
        // 弹出框抬头
        title: {
            type: String
        },

        // 导入请求url
        url: {
            type: String
        },

        // 下载模板url
        downloadTplUrl: {
            type: String
        },

        // 弹出框宽度
        width: {
            type: Number,
            default: () => 600
        },

        // 字段标签宽度
        labelWidth: {
            type: Number,
            default: () => 110
        },

        extraParams: {
            type: Object,
            default: () => {}
        }
    },

    mounted() {},

    methods: {
        beforeUpload(file) {
            this.doReset();
            this.file = file;

            return false;
        },

        /**
         *  提交
         */
        doSubmit: function () {
            this.validateMsg = null;

            if (this.validateFile(this.file)) {
                this.loading = true;
                this.$refs.uploadfile.post(this.file);
            }
        },

        /**
         *  处理上传成功
         */
        handleSuccess(response) {
            this.loading = false;

            if (response.success) {
                const result = response.result || {};
                if (result.success) {
                    this.$emit('on-import-success');
                    this.$Message.success('导入成功，导入记录数为:' + result.numberOfSuccessful);
                    this.doClose();
                } else {
                    this.reportMessage = this.formatReportMessage(result);
                }
            } else {
                this.handleFailure(response);
            }
        },

        /**
         *  处理导入失败
         */
        handleFailure(response) {
            this.$Message.error('导入失败:' + response.message);
        },

        /**
         *  处理上传失败
         */
        handleError(error) {
            this.loading = false;

            this.reportMessage = '状态码：' + error.errorCode + ', 错误信息:' + error.message;
        },

        /**
         *  清除选择文件
         */
        handleClear() {
            this.doReset();
        },

        /**
         *  下载模板
         */
        downloadTemplate() {
            window.open(this.downloadTplUrl, '_blank');
        },

        /**
         * 验证
         */
        validateFile: function (file) {
            let reg = /\.(xls|xlsx)$/i;

            if (!file) {
                this.validateMsg = '文件不能为空';
                return false;
            }

            if (!reg.test(file.name)) {
                this.validateMsg = '文件类型有误，支持格式: xls, xlsx';
                return false;
            }

            return true;
        },

        /**
         * 打开
         */
        show() {
            this.isShow = true;
        },

        /**
         *  关闭
         */
        doClose() {
            this.isShow = false;
            this.doReset();
        },

        /**
         *  重置
         */
        doReset() {
            this.loading = false;
            this.validateMsg = null;
            this.reportMessage = null;
            this.file = null;
            this.$refs.uploadfile.clearFiles();
        },

        /**
         * 格式化导入报告信息
         */
        formatReportMessage(result) {
            const { sumTotal, numberOfSuccessful, numberOfFailures, url } = result;

            return `
              <span class="message-label">导入记录数：</span>${sumTotal}, </br>
              <span class="message-label">成功记录数：</span>${numberOfSuccessful}, </br>
              <span class="message-label">失败记录数：</span>${numberOfFailures}, </br>
              <span class="message-label">导入错误报告：<span> <a href="${
                  config.resUrl + url
              }" target="_blank">下载</a>`;
        }
    }
};
</script>

// 样式
<style lang="less" scoped>
.upload-wrap {
    display: flex;
    align-items: center;
}
.file-label {
    display: inline-block;
    margin-right: 8px;
    flex: 0 0 70px;
    text-align: right;
    .required-star {
        color: red;
        font-weight: bold;
    }
}
.file-box {
    display: inline-block;
    border-radius: 4px;
    flex: 1;
    margin-right: 5px;
    &.invalid {
        .ivu-input {
            border: 1px solid red;
        }
    }
}
.file-button {
    display: inline-block;
}
.file-template {
    display: inline-block;
    margin-left: 5px;
}
.import-message-wrap {
    padding: 4px 5px;
    .message {
        margin-left: 65px;
        padding: 4px 5px;
        color: red;
    }
}
.upload-report {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .file-label {
        width: 70px;
        text-align: right;
    }
    .report-content {
        display: inline-block;
        border: 1px solid #ddd;
        border-radius: 5px;
        height: 250px;
        flex: 1;
        padding: 5px;
        .message-label {
            font-weight: 700 !important;
        }
    }
}
</style>