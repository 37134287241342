import Vue from 'vue';
import SvButton from '@/components/sv-button';
import SvIconButton from '@/components/sv-icon-button';
import SvDisplayField from '@/components/sv-display-field';
import SvTextareaField from '@/components/sv-textarea-field';
import SvComboboxField from '@/components/sv-combobox-field';
import SvInputField from '@/components/sv-input-field';
import SvVinInput from '@/components/sv-vin-input';
import SvCombobox from '@/components/sv-combobox';
import SvSearch from '@/components/sv-search';
import SvGrid from '@/components/sv-grid';
import SvEdit from '@/components/sv-edit';
import SvUpload from '@/components/sv-upload';
import SvImport from '@/components/sv-import';
import SvDetail from '@/components/sv-detail';
import SvGridEditDialog from '@/components/sv-grid-edit-dialog';
import SvBackTop from '@/components/sv-back-top';
import SvCard from '@/components/sv-card';
import SvPanelGroup from '@/components/sv-panel-group';
import SvPanelForm from '@/components/sv-panel-form';
import SvSelectorField from '@/components/sv-selector-field';
import SvSelectorGrid from '@/components/sv-selector-grid';
import SvUploadField from '@/components/sv-upload-field';
import SvTree from '@/components/sv-tree';
import SvImage from '@/components/sv-image';

import ViewportLayout from '@/layouts/viewport-layout';

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

Vue.component('SvButton', SvButton);
Vue.component('SvIconButton', SvIconButton);
Vue.component('SvDisplayField', SvDisplayField);
Vue.component('SvTextareaField', SvTextareaField);
Vue.component('SvComboboxField', SvComboboxField);
Vue.component('SvInputField', SvInputField);
Vue.component('SvVinInput', SvVinInput);
Vue.component('SvCombobox', SvCombobox);
Vue.component('SvSearch', SvSearch);
Vue.component('SvGrid', SvGrid);
Vue.component('SvEdit', SvEdit);
Vue.component('SvUpload', SvUpload);
Vue.component('SvImport', SvImport);
Vue.component('SvDetail', SvDetail);
Vue.component('SvGridEditDialog', SvGridEditDialog);
Vue.component('SvBackTop', SvBackTop);
Vue.component('SvCard', SvCard);
Vue.component('SvPanelGroup', SvPanelGroup);
Vue.component('SvPanelForm', SvPanelForm);
Vue.component('SvSelectorField', SvSelectorField);
Vue.component('SvSelectorGrid', SvSelectorGrid);
Vue.component('SvUploadField', SvUploadField);
Vue.component('SvTree', SvTree);
Vue.component('SvImage', SvImage);

Vue.component('ViewportLayout', ViewportLayout);

Vue.use(Viewer, {
    defaultOptions: {
        navbar: false,
        toolbar: false,
        button: true,
        zIndex: 99999
    }
});
