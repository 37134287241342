<template>
    <div class="page-wrap">
        <!-- 导航条 -->
        <div class="manual-header-wrap">
            <div>
                <span class="manual-title-wrap">手册资料</span>
                <span
                    :class="{ 'manual-name-wrap': item.isChecked, 'manual-name': !item.isChecked }"
                    v-for="(item, idx) in list"
                    :key="idx"
                    @click="handleClick(item)"
                    ><span class="line">&nbsp;/&nbsp;</span> <span class="text">{{ item.name }}</span></span
                >
            </div>

            <div class="manual-text-position" v-if="entranceName || modalName || manualName">
                当前位置：{{ entranceName }}
                <span v-if="modalName || manualName">/ {{ modalName }}</span>
                <span v-if="manualName"> / {{ manualName }}</span>
            </div>

            <span class="manual-search-wrap" v-if="$route.name === 'discription'">
                <Button size="small" @click="handleSearch" v-permission="'tis:manual:search'">全文搜索</Button>
            </span>
        </div>

        <!-- 全文搜索 -->
        <searchAll ref="searchAll" />

        <!-- 路由挂载 -->
        <div class="router-page">
            <router-view />
        </div>
    </div>
</template>

<script>
import searchAll from './common/searchAll.vue';
export default {
    name: 'serviceManual',
    components: { searchAll },
    data() {
        return {
            name: this.$route.query.name || '选择入口',
            list: [
                {
                    name: '选择入口',
                    path: '/serviceManual/manual',
                    isChecked: false
                },
                {
                    name: '选择车系',
                    path: '/serviceManual/modal',
                    isChecked: true
                },
                {
                    name: '选择手册',
                    path: '/serviceManual/cmanual',
                    isChecked: false
                },
                {
                    name: '手册内容',
                    path: '/serviceManual/discription',
                    isChecked: false
                },
                {
                    name: '维修资料',
                    path: '/serviceManual/videos',
                    isChecked: false
                }
            ],
            entranceId: '',
            modalPath: '/serviceManual/modal',
            entranceName: '',
            modalName: '',
            manualName: ''
        };
    },

    watch: {
        $route(to) {
            this.list = [
                {
                    name: '选择入口',
                    path: '/serviceManual/manual',
                    isChecked: false
                },
                {
                    name: '选择车系',
                    path: '/serviceManual/modal',
                    isChecked: true
                },
                {
                    name: '选择手册',
                    path: '/serviceManual/cmanual',
                    isChecked: false
                },
                {
                    name: '手册内容',
                    path: '/serviceManual/discription',
                    isChecked: false
                },
                {
                    name: '维修资料',
                    path: '/serviceManual/videos',
                    isChecked: false
                }
            ];

            switch (to.name) {
                case 'manual':
                    this.list = this.list.slice(0, 1);
                    this.list.forEach(item => {
                        item.isChecked = false;
                    });
                    this.list[this.list.length - 1].isChecked = true;
                    break;
                case 'modal':
                    this.list = this.list.slice(0, 2);
                    this.list.forEach(item => {
                        item.isChecked = false;
                    });
                    this.list[this.list.length - 1].isChecked = true;
                    break;
                case 'cmanual':
                    this.list = this.list.slice(0, 3);
                    this.list.forEach(item => {
                        item.isChecked = false;
                    });
                    this.list[this.list.length - 1].isChecked = true;

                    break;
                case 'discription':
                    this.list = this.list.slice(0, 4);
                    this.list.forEach(item => {
                        item.isChecked = false;
                    });
                    this.list[this.list.length - 1].isChecked = true;
                    break;
                case 'videos':
                    this.list = this.list.slice(0, 3).concat(this.list.slice(-1));
                    this.list.forEach(item => {
                        item.isChecked = false;
                    });
                    this.list[this.list.length - 1].isChecked = true;
                    break;
                default:
                    break;
            }
        },
        deep: true,
        immediate: true
    },

    created() {
        switch (this.$route.name) {
            case 'manual':
                this.list = this.list.slice(0, 1);
                this.list.forEach(item => {
                    item.isChecked = false;
                });
                this.list[this.list.length - 1].isChecked = true;
                break;
            case 'modal':
                this.list = this.list.slice(0, 2);
                this.list.forEach(item => {
                    item.isChecked = false;
                });
                this.list[this.list.length - 1].isChecked = true;
                break;
            case 'cmanual':
                this.list = this.list.slice(0, 3);
                this.list.forEach(item => {
                    item.isChecked = false;
                });
                this.list[this.list.length - 1].isChecked = true;

                break;
            case 'discription':
                this.list = this.list.slice(0, 4);
                this.list.forEach(item => {
                    item.isChecked = false;
                });
                this.list[this.list.length - 1].isChecked = true;
                break;
            case 'videos':
                this.list = this.list.slice(0, 3).concat(this.list.slice(-1));
                this.list.forEach(item => {
                    item.isChecked = false;
                });
                this.list[this.list.length - 1].isChecked = true;
                break;
            default:
                break;
        }
        this.$eventBus.$on('changeEntranceName', entranceName => {
            this.entranceName = entranceName;
        });
        this.$eventBus.$on('changeModalName', modalName => {
            this.modalName = modalName;
        });
        this.$eventBus.$on('changeManualType', manualName => {
            this.manualName = manualName;
        });
    },

    methods: {
        handleSearch() {
            this.$refs.searchAll.show(this.optionParams);
        },
        handleClick(item) {
            this.entranceId = this.$route.query.entranceId || '';
            this.seriesCode = this.$route.query.seriesCode || '';
            this.manualTypeCode = this.$route.query.manualTypeCode || '';

            if (item.name === '选择车系' && this.entranceId) {
                this.manualName = '';
                this.modalName = '';
                this.$router.push({
                    path: item.path,
                    query: {
                        entranceId: this.entranceId,
                        manualTypeCode: this.manualTypeCode
                    }
                });
            } else if (item.name === '选择手册' && this.entranceId && this.seriesCode) {
                this.manualName = '';
                this.$router.push({
                    path: item.path,
                    query: {
                        entranceId: this.entranceId,
                        seriesCode: this.seriesCode,
                        manualTypeCode: this.manualTypeCode
                    }
                });
            } else if (item.name === '选择入口' && this.manualTypeCode) {
                this.manualName = '';
                this.modalName = '';
                this.entranceName = '';
                this.$router.push({
                    path: item.path,
                    query: {
                        code: this.manualTypeCode
                    }
                });
            } else {
                this.manualName = '';
                this.modalName = '';
                this.entranceName = '';
                this.$router.push(item.path);
            }
        }
    }
};
</script>

<style lang="less" scoped>
.page-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;

    .manual-header-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        height: 44px;
        border-bottom: 1px solid @border-color;

        .manual-title-wrap {
            margin-left: 20px;
            font-size: 17px;

            .back-icon {
                cursor: pointer;
                font-size: 20px;
                margin-right: 20px;
            }
        }

        .manual-text-position {
            margin: 0 auto;
            text-align: center;
        }

        .manual-search-wrap {
            text-align: right;
        }
    }

    .router-page {
        flex: 1;
        overflow-y: auto;
    }

    .manual-name-wrap {
        font-size: 17px;
        font-weight: 700;
        cursor: pointer;

        .line {
            font-weight: normal;
        }
    }

    .manual-name {
        font-size: 17px;
        cursor: pointer;

        .line {
            font-weight: normal;
        }

        :hover {
            color: #57a3f3;
        }
    }
}
</style>
