import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQClafI67AUD5BgsuNz/DRyx16r5iJE/t9hJtXCInrEZaDcDBfgj2P43url1bvbShkILu/684QdVUX4N2P6bz8JVmwvb8Cv2MMpMvfbKpELm6BKDVDSPP7qYkQQyg27yM14ntwdW1QMigbhxMj3hQvRHHlVLH/JEo37EXvI2XziytwIDAQAB';

const privateKey =
    'MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAKVp8jrsBQPkGCy43P8NHLHXqvmIkT+32Em1cIiesRloNwMF+CPY/je6uXVu9tKGQgu7/rzhB1VRfg3Y/pvPwlWbC9vwK/Ywyky99sqkQuboEoNUNI8/upiRBDKDbvIzXie3B1bVAyKBuHEyPeFC9EceVUsf8kSjfsRe8jZfOLK3AgMBAAECgYAB3280p5Nu9dgoOXeXE6jtgomXBarPU9+JBhreER3kMb/dsuWaUkyeiOisobL9szNwOpKpxMp2dtO8iGZpkTKcDNhvSf9iWos/kf21mED/KjZls6XA92tfHb6cuufovFX4GxD0sTQHwc73CR30dHeqAlXs38AhdnRPJcChrqT9EQJBANobMp8+onF9/ciXIeNjoOXnPm+wH1C1Arj7g7BNymt9LUaF/hHB9P9f+auPAp8pTE3tN1NX8uSJBZTGUt5sxxMCQQDCJyCKqDQH42KKqwPZglIDApZM5jWRD8A9j8OxGP+suX6RQqM7Eeyzx0UgN6SIJpbCoO6mTf9uTjTDWO97vCZNAkBK8WOAkm4Q30DvxvaPArHLrZynlfX8rmXkGBBU6TXvn1CKVgpKIcJEab8Jcxdshy0/7Y3AJo1Ad9CiIU9iPDBfAkBxOHQHp/M/rmBm0Tfbfd3pl3aivZT7VR4cSFwsSJHA3iNq/ltGNTu90zUChrBSHX7JXSQOo2mTn9OxH4n4tE35AkBxhfnbpIX5PRXtBZKRKx+Umb/AGKlrL4ngyBEstkE7qNr+Mf4/yL1kTp1nEC3yhQVI3g4TMUmaKW6NyJTGTNVC';

// 加密
export function encrypt(txt) {
    const encryptor = new JSEncrypt();
    encryptor.setPublicKey(publicKey); // 设置公钥
    return encryptor.encrypt(txt); // 对需要加密的数据进行加密
}

// 解密
export function decrypt(txt) {
    const encryptor = new JSEncrypt();
    encryptor.setPrivateKey(privateKey);
    return encryptor.decrypt(txt);
}
