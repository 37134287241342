<template>
    <div class="main">
        <Navigation></Navigation>
        <div class="main-content-wrap">
            <router-view />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import HeaderBar from '../common/header-bar';
import Navigation from './navigation';
import watermark from '@/common/watermark';
import { storage } from '@/common/storage';

export default {
    name: 'MainLayout',
    components: {
        // HeaderBar,
        Navigation
    },
    data() {
        return {
            isMiniMenu: false
        };
    },
    computed: {
        ...mapGetters('router', ['cachedPages', 'menuList'])
    },
    mounted() {
        if (!document.querySelector('.watermark')) {
            let userInfo = Object.keys(storage.getUserInfo()).length == 0 ? { enterpriseName: '', nickName: '' } : JSON.parse(storage.getUserInfo());

            let date = this.getDate();

            //添加水印
            watermark.set(userInfo.enterpriseName + '-' + userInfo.nickName, date);
        }
        this.$eventBus.$on('closeTag', () => {
            this.include = [];
        });
    },
    methods: {
        handleNavTagClick(item) {
            this.$refs.sideMenu.setOpenedNames(item.code);
        },
        onTriggerMenu() {
            this.isMiniMenu = !this.isMiniMenu;
        },
        getDate() {
            let date = new Date();
            let year = date.getFullYear();    //  返回的是年份
            let month = date.getMonth() + 1;  //  返回的月份上个月的月份，记得+1才是当月
            let dates = date.getDate();       //  返回的是几号

            return `${year}-${month}-${dates}`
        }

    }
};
</script>
<style lang="less" scoped>
// @import '~@/less/sdk/index';
.main {
    display: flex;
    flex-direction: column;
    height: 100%;

    .main-content-wrap {
        height: calc(100% - 48px);
        flex: 1;
    }
}
</style>
