import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './baseRouter';
import { storage } from '@/common/storage';
// import auth from '@/common/auth';
import iView from 'view-design';

Vue.use(VueRouter);

// fix: NavigationDuplicated: Avoided redundant navigation to current location:
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
VueRouter.prototype.replace = function push(location) {
    return originalReplace.call(this, location).catch(err => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

let isRouterLoaded = false;

router.beforeEach(async (to, from, next) => {
    const token = storage.getToken();
    iView.LoadingBar.start();

    document.title = to.meta.title;

    // 无需授权
    if (to.meta.notAuth === true) {
        return next();
    }

    if (!token) {
        router.push('/login');

    } else {
        if (!isRouterLoaded) {
            isRouterLoaded = true;
        }
        // auth();
        next();
    }
});

router.afterEach(to => {
    storage.setLastRoute(to.fullPath);
    iView.LoadingBar.finish();
});

export default router;
