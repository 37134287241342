import vue from 'vue';
import { request } from '@/network/request';
import MainLayout from '@/layouts/main-layout';
import menuList1 from '@/data/menuList';

// 转换菜单树据结构key
function mappingMenuList(data, paths, level) {
    return data.map(item => {
        paths = paths ? [...paths, item.code] : [item.code];

        return {
            id: item.code,
            text: item.title,
            level: level,
            iconCls: item.icon,
            to: item.uri,
            page: item.uriPage,
            paths: paths,
            children: item.children ? mappingMenuList(item.children, paths, level + 1) : []
        };
    });
}

// 获取远程的菜单列表
async function getRemoteMenuList() {
    let message = '';
    let menuList = [];

    try {
        const data = await request.get('/sso/user/menu');

        if (data.success) {
            const list = data.result || [];

            menuList = mappingMenuList(list, null, 1);
        } else {
            message = data.message;
        }
    } catch (e) {
        message = JSON.stringify(e);
    }

    if (message) {
        vue.prototype.$Notice.error({
            title: '提示',
            desc: message
        });
    }

    return menuList;
}

// 将菜单数据转换路由
function getRouterByMenu(menuList) {
    let items = [];

    for (let item of menuList) {
        let subItem = [];

        if (item.page) {
            items.push({
                name: item.id,
                path: item.to,
                target: item.target,
                meta: {
                    title: item.text
                },
                component: resolve => require([`@/pages/${item.page}`], resolve)
            });
        }

        if (item.children && item.children.length) {
            subItem = getRouterByMenu(item.children);
        }

        if (subItem.length) {
            items = items.concat(subItem);
        }
    }
    return items;
}

// 获取菜单路由
export async function getMenuRouter() {
    const menuList = await getRemoteMenuList();
    const routerList = getRouterByMenu(menuList1);
    const normalRouter = routerList.filter(item => item.target === '_blank');
    const iframeRouter = routerList.filter(item => item.target !== '_blank');

    const iframeMenuRouter = {
        path: '/',
        component: MainLayout,
        redirect: '/announcement',
        children: iframeRouter
    };

    return { menuList, iframeMenuRouter, normalRouter };
}
