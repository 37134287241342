<template>
    <!-- 全文搜索 -->
    <Drawer :closable="false" v-model="showPanel" :width="800">
        <div class="page-search-wrap">
            <!-- 头部 -->
            <div class="header-wrap">
                <div class="title-wrap">全文搜索</div>
                <div class="btns-wrap">
                    <span class="search-box-wrap">
                        <Input
                            suffix="ios-search"
                            v-model="keyword"
                            @on-enter="handleSearch"
                            @on-search="handleSearch"
                            search
                            enter-button
                            clearable
                        />
                    </span>
                    <span class="close-icon-wrap">
                        <Icon type="md-close" @click="handleClose" />
                    </span>
                </div>
            </div>

            <!-- 内容 -->
            <div class="body-wrap">
                <div
                    v-for="item in list"
                    :class="{ 'list-cell': true, actived: item.selected }"
                    @click="handleRowClick(item)"
                    :key="item.id"
                >
                    <span class="topic-title">{{ item.title }}</span>
                    <span class="topic-content" v-html="item.content"></span>
                </div>
                <NoRecord v-if="list.length === 0" />
            </div>

            <!-- 翻页 -->
            <div class="paging-wrap">
                <Page
                    :total="pagingData.total"
                    @on-change="handleGotoPage"
                    :current="pagingData.page"
                    :pageSize="pagingData.size"
                />
            </div>

            <!-- loading -->
            <Spin size="large" fix v-if="showLoading"></Spin>
        </div>
        <div slot="footer"></div>
    </Drawer>
</template>

<script>
import { request } from '@/common';
import NoRecord from '@/pages/common/noRecord.vue';

export default {
    components: {
        NoRecord
    },
    data() {
        return {
            showPanel: false,
            keyword: '',
            list: [],
            showLoading: false,
            optionParams: {},
            pagingData: {
                page: 1,
                size: 20,
                total: 0
            }
        };
    },
    watch: {
        keyword(val) {
            if (this.isEmpty(val)) {
                this.reset();
            }
        }
    },
    methods: {
        show(optionParams) {
            this.optionParams = optionParams;
            this.showPanel = true;
        },

        handleGotoPage(page) {
            this.pagingData.page = page;

            this.loadData();
        },

        handleSearch() {
            this.loadData();
        },

        loadData() {
            const url = `/manual/doc/content-search/${this.$route.query.id}`;
            const { page, size } = this.pagingData;
            const params = this.getPageQueryParams({ type: '1', kw: this.keyword }, page, size);

            this.showLoading = true;

            request
                .get(url, params)
                .then(data => {
                    if (data.success) {
                        this.pagingData.total = data.result.totalCount;
                        this.list = this.getHignlight(data);
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {
                    this.showLoading = false;
                });
        },

        getHignlight(data) {
            let list = data.result.data || [];
            list.forEach(item => {
                if (!item.title) {
                    item.title = this.highlightNode(item.title, this.keyword);
                }
                item.content = this.highlightNode(item.content, this.keyword);
            });

            return list;
        },

        getParams() {
            const { page, size } = this.pagingData;
            const params = { keyword: this.keyword, manualCode: this.$route.query.code, ...this.optionParams };

            return this.getPageQueryParams(params, page, size);
        },

        handleRowClick(item) {
            this.list.map(item => (item.selected = false));
            this.$set(item, 'selected', true);
            this.$eventBus.$emit('onClickSearchListItem', item);
        },

        handleClose() {
            this.showPanel = false;
        },

        // 高亮节点
        highlightNode(title, keyword) {
            if (!this.isEmpty(title)) {
                const regexp = new RegExp(keyword, 'g');
                return title.replace(regexp, `<span style="color:#616DF1;">${keyword}</span>`);
            } else {
                return title;
            }
        },

        reset() {
            this.list = [];
            this.pagingData.total = 0;
        }
    }
};
</script>

<style lang="less" scoped>
.page-search-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    .header-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid @border-color;
        padding: 5px;
        .title-wrap {
            font-size: 16px;
            font-weight: 700;
        }
        .btns-wrap {
            display: flex;
            align-items: center;
            .search-box-wrap {
                margin-right: 10px;
            }
            .close-icon-wrap {
                font-size: 18px;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    .body-wrap {
        flex: 1;
        padding: 5px 10px;
        border-bottom: 1px solid @border-color;
        overflow-y: auto;
        .list-cell {
            display: flex;
            cursor: pointer;
            flex-direction: column;
            border-bottom: 1px dotted @border-color;
            padding: 5px;
            .topic-title {
                font-weight: 700;
            }
            .topic-content {
                /deep/ span {
                    font-weight: 700;
                    color: @primary-color;
                }
            }
            &:hover {
                background: @light-primary-color;
            }
            &.actived {
                background: #dfe2fa;
            }
        }
    }

    .paging-wrap {
        padding: 5px;
    }
}
</style>
