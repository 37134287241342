<template>
    <div class="navigation clearfix">
        <!-- logo -->
        <div class="left-box">
            <a href="/home" class="logo"><img src="@/assets/images/tis_home_logo.png" alt="" /></a>
        </div>
        <!-- 导航栏 -->
        <div class="right-box">
            <span class="nav-item" title="首页" v-if="!['home'].includes($route.name)">
                <a href="/home" class="iconfont icon-home"></a>
            </span>
            <span class="nav-item" title="AI复合查询" v-permission="'tis:ai-search:list'">
                <a href="/mixedQuery" target="_blank" class="iconfont icon-all-search"></a>
            </span>
            <span
                class="nav-item"
                v-if="['home', 'boutique'].includes($route.name)"
                title="购物车"
                v-permission="'tis:shopping-cart:list'"
            >
                <Badge :count="shopppingCartCount" key="dot">
                    <a href="/shoppingCart" target="_blank" class="iconfont icon-shopping"></a>
                </Badge>
            </span>
            <span class="nav-item" title="消息" v-permission="'tis:info-notice:*'">
                <a href="/messages" target="_blank" class="iconfont icon-notification"></a>
            </span>
            <span class="nav-item">
                <Dropdown>
                    <span class="nav-btn btn">
                        {{ username }}
                        <Icon type="ios-arrow-down"></Icon>
                    </span>
                    <DropdownMenu slot="list">
                        <DropdownItem @click.native="handleLogout"> 退出登录 </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </span>
            <span class="nav-item" @click="opentismUrl" v-permission="'tis:tism:*'">
                <span class="iconfont icon-icon-tis-administrator"></span>&nbsp;&nbsp;
                <span>管理中心</span>
            </span>
            <span class="nav-item">
                <Dropdown placement="bottom-end">
                    <span class="iconfont icon-settings"></span>
                    <DropdownMenu slot="list">
                        <DropdownItem @click.native="setUserInfo"> 用户信息 </DropdownItem>
                        <DropdownItem @click.native="editPsw"> 修改密码 </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </span>
        </div>

        <sv-edit ref="edit" modelTitle="设置" :labelWidth="90" :isShowFooter="false">
            <item type="row">
                <item type="col">
                    <item name="userId" type="textbox" hidden="true" />
                    <item name="id" type="textbox" hidden="true" />
                    <item name="username" label="账号:" type="textbox" required="true" disabled="true" />
                    <item name="enterpriseName" label="经销商:" type="textbox" required="true" disabled="true" />
                    <item name="email" label="E-MAIL:" type="textbox" required="true" />
                    <item name="nickName" label="姓名:" type="textbox" required="true" />
                    <item name="telephone" label="手机号:" type="textbox" required="true" />
                </item>
            </item>

            <div slot="footer-btn">
                <Button @click="doClose()">关闭</Button>
                <Button type="primary" @click="doSubmit()">保存</Button>
            </div>
        </sv-edit>

        <sv-edit ref="edit1" modelTitle="修改密码" :labelWidth="90" :isShowFooter="false">
            <item type="row">
                <item type="col">
                    <item name="pwd" label="旧密码:" type="textbox" required="true" />
                    <item name="newPwd" label="新密码:" type="textbox" required="true" />
                    <item name="newPwd1" label="确认密码:" type="textbox" required="true" />
                </item>
            </item>

            <div slot="notice" class="notice"><span>密码必须为8-16位，且必须包含大小写字母和数字(避免简单111111，123456这种简单密码)</span></div>

            <div slot="footer-btn">
                <Button @click="confirmEdit()">确认修改</Button>
                <Button type="primary" @click="doClose1()">取消</Button>
            </div>
        </sv-edit>
    </div>
</template>
<script>
import { crud } from '@/mixins/crud';
import { request } from '@/network/request';
import { storage } from '@/common/storage';
import config from 'config';
import { encrypt } from '@/common/rsaEncrypt';

export default {
    mixins: [crud],
    components: {},
    data() {
        let userInfo =
            Object.keys(storage.getUserInfo()).length == 0 ? { nickName: '' } : JSON.parse(storage.getUserInfo());

        return {
            username: userInfo.nickName,
            tismUrl: config.tismURL + '?token=' + storage.getToken() + '&username=' + userInfo.nickName,
            shopppingCartCount: 0
        };
    },
    conputed: {},
    created() {
        this.initPage();
        this.$eventBus.$on('refreshCart', () => {
            this.getShoppingCartCount();
        });
    },
    mounted() {},
    methods: {
        initPage() {
            storage.getUserInfo();

            if (['home', 'boutique'].includes(this.$route.name)) {
                this.getShoppingCartCount();
            }
        },
        opentismUrl() {
            window.open(this.tismUrl);
        },

        confirmEdit() {
            const value = this.$refs.edit1.dataModel;
            const passwordRegex = /^(?!.*((.)\2{2,}))(?!.*(123|234|345|456|567|678|789|890|012|abc|xyz))(?!.*(qwerty|asdfghjkl|zxcvbnm|12345678))(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

            if (!passwordRegex.test(value.newPwd)) {
                this.$Message.error('密码必须为8-16位，必须包含大小写字母和数字，不可出现用户名相同字符，且密码不可为111、123、345这种简单密码');
                return;
            }

            if (!passwordRegex.test(value.newPwd1)) {
                this.$Message.error('密码必须为8-16位，必须包含大小写字母和数字，不可出现用户名相同字符，且密码不可为111、123、345这种简单密码');
                return;
            }

            if (value.newPwd != value.newPwd1) {
                this.$Message.error('两次密码不一致');
                return;
            }
            const userId = JSON.parse(storage.getUserInfo()).userId || '';
            const values = {
                userId: userId,
                newPwd: encrypt(value.newPwd),
                newPwd1: encrypt(value.newPwd1),
                pwd: value.pwd
            };

            request
                .post('/oauth/oauth/editPwd', values)
                .then(data => {
                    if (data.success) {
                        this.$Message.success('修改成功');
                        this.doClose1();
                    } else {
                        this.$Message.error(data.message);
                    }
                })
                .finally(() => {});
        },

        // 退出登录
        handleLogout() {
            this.$Spin.show();

            request
                .post('/oauth/oauth/logout')
                .then(data => {
                    if (data.success) {
                        storage.removeStorage();
                        window.location.href = './login';
                        this.$Message.success('退出成功');
                    }
                })
                .finally(() => {
                    this.$Spin.hide();
                });
        },

        // 设置用户信息
        setUserInfo() {
            this.$refs.edit.show();

            const values = this.getValues();

            this.$refs.edit.setValues(values);
        },

        // 修改密码
        editPsw() {
            this.$refs.edit1.show();
        },

        getValues() {
            const userInfo = JSON.parse(storage.getUserInfo()) || {};

            return {
                userId: userInfo.userId,
                enterpriseName: userInfo.enterpriseName,
                nickName: userInfo.nickName,
                email: userInfo.email,
                username: userInfo.username,
                telephone: userInfo.telephone
            };
        },

        doClose() {
            this.$refs.edit.doClose();
        },

        doClose1() {
            this.$refs.edit1.doClose();
        },

        doSubmit() {
            const params = this.$refs.edit.getParams();

            request
                .post('/oauth/oauth/update', params)
                .then(data => {
                    if (data.success) {
                        this.storageUserInfo();
                        this.$Message.success('保存成功');
                        this.doClose();
                    }
                })
                .finally(() => {});
        },

        /**
         * 存储用户信息
         */
        async storageUserInfo() {
            await request
                .get('/oauth/oauth/user_info?userId=' + JSON.parse(storage.getUserInfo()).userId)
                .then(data => {
                    let userInfo = this.getUserInfo(data.result) || {};

                    storage.setUserInfo(JSON.stringify(userInfo));
                });
        },

        /**
         * 构建用户信息
         */
        getUserInfo(data) {
            return {
                nickName: data.nickname,
                username: data.username,
                userId: data.userId,
                email: data.email,
                telephone: data.telephone,
                enterpriseName: data.enterpriseName
            };
        },

        // 获取购物车数量
        getShoppingCartCount() {
            const params = this.getPageQueryParams({}, 1, 10);

            request
                .get('/mh/shopping/cart/page', params)
                .then(data => {
                    if (data.success) {
                        this.shopppingCartCount = data.result.total;
                    }
                })
                .finally(() => {});
        }
    }
};
</script>
<style lang="less" scoped>
@import '~@/less/sdk/index';

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding: 9px 0;
    background-color: #fff;
    border-bottom: 1px @border-color solid;

    /deep/.ivu-badge-count {
        top: 5px;
        right: -2px;
        height: 12px;
        line-height: 10px;
        padding: 0px 4px;
        font-size: 10px;
    }

    .left-box {
        display: flex;
        align-items: center;
        padding-left: 18px;

        .logo {
            img {
                width: 175px;
                height: 30px;
            }
        }
    }

    .right-box {
        display: flex;
        align-items: center;
        text-align: center;

        .nav-item {
            display: inline-block;
            border-left: 1px @border-color solid;
            padding: 0 30px;
            height: 48px;
            line-height: 48px;
            cursor: pointer;

            .iconfont {
                font-size: 18px;
                color: #001467;
            }

            .icon-all-search {
                font-size: 22px;
            }

            span {
                font-size: 14px;
                color: #001467;
            }
        }
    }

    .icon-settings {
        cursor: pointer;
    }
}

/deep/.notice {
    margin-left: 90px;
    font-weight: 500;
}
</style>
