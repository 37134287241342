<template>
    <div class="images-wrap" :style="style" v-viewer>
        <a v-show="isShowDownload" title="下载图片" class="download" :href="imageSrc" target="_blank" download>
            <Icon type="md-download" />
        </a>
        <img ref="img" :src="imgObject.thumbnail" :data-src="imgObject.source" :style="imageStyle" />
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        prefixSrc: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        },
        errorSrc: {
            type: String,
            default: ''
        },
        isShowDownload: {
            type: Boolean,
            default: () => true
        }
    },

    data() {
        return {
            imageSrc: '',
            imageStyle: {
                width: 'auto',
                height: 'auto'
            }
        };
    },

    computed: {
        style() {
            if (this.width && this.height) {
                return 'width:' + this.width + 'px;height:' + this.height + 'px';
            } else {
                return 'height: 100%; width: 100%;';
            }
        },
        imgObject() {
            if (this.width && this.height) {
                return {
                    thumbnail: this.imageSrc + `?x-oss-process=image/resize,m_fill,h_${this.height},w_${this.width}`,
                    source: this.imageSrc
                };
            } else {
                return {
                    thumbnail: this.imageSrc,
                    source: this.imageSrc
                };
            }
        }
    },

    watch: {
        src() {
            this.loadImage();
        }
    },

    mounted() {
        this.loadImage();
    },

    methods: {
        loadImage: function () {
            const self = this;
            const img = new Image();

            img.onload = function () {
                self.setImgSize(this);
            };

            img.onerror = function () {
                let errImg = new Image();
                let src = self.errorSrc;

                errImg.onload = function () {
                    self.setImgSize(this, src);
                };

                errImg.src = src;
            };

            img.src = this.prefixSrc + this.src;
        },

        setImgSize(img) {
            const image = this.$refs.img;
            const outerWidth = image.parentElement.clientWidth;
            const outerHeight = image.parentElement.clientHeight;
            const imgWidth = img.width;
            const imgHeihgt = img.height;
            const size = this.scalingDownCalc(imgWidth, imgHeihgt, outerWidth, outerHeight);
            const imageStyle = {
                width: size.width + 'px',
                height: size.height + 'px',
                top: size.top + 'px',
                left: size.left + 'px'
            };

            this.imageSrc = img.src;
            this.imageStyle = imageStyle;
        },

        scalingDownCalc: function (iW, iH, mW, mH) {
            let newW = 0;
            let newH = 0;
            let top = 0;
            let left = 0;

            if (iH / iW >= mH / mW) {
                if (iH > mH) {
                    newH = mH;
                    newW = (iW * mH) / iH;
                } else {
                    newW = iW;
                    newH = iH;
                }
            } else {
                if (iW > mW) {
                    newW = mW;
                    newH = (iH * mW) / iW;
                } else {
                    newW = iW;
                    newH = iH;
                }
            }

            // newH = 'auto';
            // newW = '100%';
            // newH = newH - 20;
            // newW = newW - 20;
            newH = newH - 10;
            newW = newW - 10;

            top = (mH - newH) / 2;
            left = (mW - newW) / 2;

            return {
                width: newW,
                height: newH,
                top: top,
                left: left
            };
        }
    }
};
</script>

<style lang="less" scoped>
@import '~@/less/sdk/index';

.images-wrap {
    display: inline-block;
    position: relative;
    margin: 0 auto;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 3px #888888;
    overflow: hidden;
    height: 100%;
    width: 100%;

    img {
        position: absolute;
    }

    .download {
        position: absolute;
        text-align: center;
        top: 0;
        right: 0;
        width: 23px;
        height: 23px;
        z-index: 999;
        background-color: #1890ff;
        color: #fff;
        line-height: 18px;
        border-radius: 3px;
        cursor: pointer;
    }
}
</style>
