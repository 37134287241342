import { storage } from '@/common/storage';

const permission = {
    inserted: function (el, binding) {
        let permissionList = storage.getPermission();
        let value = binding.value;
        if (value) {
            if (!permissionList) {
                el.parentNode && el.parentNode.removeChild(el);
                return;
            }
            if (permissionList.indexOf(value) === -1) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        }
    }
};

export default permission;
