import MainLayout from '@/layouts/main-layout'
import serviceManual from '@/pages/serviceManual'
import detailPageRouter from './routers/detailPageRouter'

export default [
    {
        path: '/',
        redirect: '/login'
    },
    {
        name: 'login',
        path: '/login',
        meta: {
            title: '北汽TIS登录',
            notAuth: true
        },
        component: () => import('@/pages/login')
    },
    {
        name: 'pureManual',
        path: '/pureManual',
        meta: {
            title: '手册资料',
            notAuth: true
        },
        component: () => import('@/pages/pureManual')
    },
    {
        name: 'pureVideo',
        path: '/pureVideo',
        meta: {
            title: '维修资料',
            notAuth: true
        },
        component: () => import('@/pages/pureVideo')
    },
    {
        name: 'pdfView',
        path: '/pdf-view',
        meta: {
            title: '通知预览',
            notAuth: true
        },
        component: () => import('@/pages/pdfView')
    },
    {
        name: 'preView',
        path: '/pre-view',
        meta: {
            title: '通知预览',
            notAuth: true
        },
        component: () => import('@/pages/preView')
    },
    {
        name: '',
        path: '/home',
        component: MainLayout,
        children: [
            {
                name: 'home',
                path: '/home',
                meta: {
                    title: '首页'
                },
                component: () => import('@/pages/home')
            },
            {
                name: 'mixedQuery',
                path: '/mixedQuery',
                meta: {
                    title: 'AI复合查询'
                },
                component: () => import('@/pages/mixedQuery')
            },
            {
                name: 'newInfo',
                path: '/newInfo',
                meta: {
                    title: '最新通知'
                },
                component: () => import('@/pages/newInfo')
            },
            {
                name: 'messages',
                path: '/messages',
                meta: {
                    title: '消息'
                },
                component: () => import('@/pages/messages')
            },
            {
                name: 'shoppingCart',
                path: '/shoppingCart',
                meta: {
                    title: '购物车'
                },
                component: () => import('@/pages/shoppingCart')
            },
            {
                name: 'boutique',
                path: '/boutique',
                meta: {
                    title: '精品及改装件'
                },
                component: () => import('@/pages/boutique')
            },
            {
                name: 'workhour',
                path: '/workhour',
                meta: {
                    title: '工时查询'
                },
                component: () => import('@/pages/workhour')
            },
            {
                name: 'diagnosereply',
                path: '/diagnosereply',
                meta: {
                    title: '诊断仪反馈'
                },
                component: () => import('@/pages/diagnosereply')
            },
            {
                name: 'newDiagnosereply',
                path: '/diagnosereply/newDiagnosereply',
                meta: {
                    title: '新建诊断仪反馈详情'
                },
                component: () => import('@/pages/diagnosereply/newDiagnosereply')
            },
            {
                name: 'diagnosesoft',
                path: '/diagnosesoft',
                meta: {
                    title: '软件仓库'
                },
                component: () => import('@/pages/diagnosesoft')
            },
            {
                name: 'engineering',
                path: '/engineering',
                meta: {
                    title: '工程咨询'
                },
                component: () => import('@/pages/engineering')
            },
            {
                name: 'serviceManual',
                path: '/serviceManual',
                meta: {
                    title: '手册资料'
                },
                component: serviceManual,
                children: [
                    {
                        name: 'manual',
                        path: '/serviceManual/manual',
                        meta: {
                            title: '选择入口'
                        },
                        component: () => import('@/pages/serviceManual/manual')
                    },
                    {
                        name: 'modal',
                        path: '/serviceManual/modal',
                        meta: {
                            title: '选择车系'
                        },
                        component: () => import('@/pages/serviceManual/modal')
                    },
                    {
                        name: 'cmanual',
                        path: '/serviceManual/cmanual',
                        meta: {
                            title: '选择手册'
                        },
                        component: () => import('@/pages/serviceManual/cmanual')
                    },
                    {
                        name: 'discription',
                        path: '/serviceManual/discription',

                        meta: {
                            title: '手册内容',
                            notAuth: true
                        },
                        component: () => import('@/pages/serviceManual/discription')
                    },
                    {
                        name: 'videos',
                        path: '/serviceManual/videos',
                        meta: {
                            title: '维修资料',
                            notAuth: true
                        },
                        component: () => import('@/pages/serviceManual/videos')
                    }
                ]
            },
            ...detailPageRouter
        ]
    }
]
